<template>
  <div class="my-second info bgcolor">
      <Head :title="$t('info.change_user_name')" :show="true" :right="true" :cancel="true" >
          <span class="head-success" @click="onFinish">{{$t('info.complate')}}</span>
      </Head>
      <div class="common-box info-box">
          <van-field   class="my-nickname" v-model="nickname" :placeholder="$t('info.input_user_name')" clearable />
      </div>
  </div>
</template>

<script>
import {Notify,Toast} from "vant"
import {myinfo} from "@/common"
export default {
    mixins:[myinfo],
    data(){
        return {
            nickname:"",
            mixins:[myinfo],
        }
    },
    methods:{
        onFinish(){
            if(this.nickname){
                this.$ajax.updateuser({ nickname:this.nickname})  // 提示修改失败
                .then(res=>{
                    if(res.code==200){
                        this.setdashboard({...this.user,username:this.nickname});
                        // Notify({ type: 'success', message: res.msg });
                        this.$router.go(-1);
                    }
                    
                })
            }else{
                Toast.fail(this.$t('info.user_name_noempty'))
            }
            
        }
    },
    mounted(){
        this.nickname = this.$route.query.nickname
    }
}
</script>


